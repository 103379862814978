import React from 'react';
import { withAuthorization } from '../Session';
import GrupRow from '../GrupRow';
import { Table } from 'reactstrap';
import moment from "moment";
import 'moment/locale/ca';

class Resume extends React.Component {
    constructor(props) {
        super(props);

        this.colRef = this.props.firebase.getGrups();

        this.currentMonth = moment().format('M')-1;
        this.currentYear = moment().format('YYYY');

        // MONTHS DEFINITION WITH INTEGERS -> 0 IS JANUARY
        this.months = [];
        for( let m=2;m<=this.currentMonth;m++ ){
            this.months.push(m);
        }

        this.state = {
            grups: [],
            fetching: false,
            totals: [],
            monthOrder: this.currentMonth
        };

        for( let month of this.months ){
            this.state.totals[month] = 0;
        }

    }

    componentDidMount() {
        this.unsubscribeCol = this.colRef.onSnapshot(this.onColUpdate);
        this.setState({fetching: true});
    }

    componentWillUnmount() {
        this.unsubscribeCol();
    }

    myCallback = (dataFromChild, month, grup) => {

        const {totals} = this.state;

        totals[month]=(parseFloat(totals[month])+parseFloat(dataFromChild)).toFixed(2);

        //for( let obj of this.state.grups ){
        for( let i=0;i<this.state.grups.length;i++ ){
            if( this.state.grups[i].data['clau']===grup ){

                let tempVar = this.state.grups;
                tempVar[i].data['accumulated'][month] += dataFromChild;

                this.setState({grups:tempVar});

                break;
            }
        }

        this.setState({totals:totals});

        if( month !== -1 && month === this.state.monthOrder ){
            this.sortColumn(month);
        }

    };

    onColUpdate = (snapshot) => {

        const grups = snapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            data: docSnapshot.data()
        }));

        for( let i=0;i<grups.length;i++ ){
            if( !grups[i].data['accumulated'] ) {
                grups[i].data['accumulated'] = [];
                for( let month of this.months ){
                    grups[i].data['accumulated'][month] = 0;
                }
            }
        }


        this.setState({
            grups: grups,
            fetching: false
        });

    };

    printHeadersTable = () => {

        // TODO això s'hauria de fer amb components mapejats perquè entra masses cops aqui

        let result = [];
        for( let month of this.months ){
            result.push(<th key={month.toString()} ><span className={this.state.monthOrder===month?'active month-title':'month-title'}
                                  onClick={() => this.sortColumn(month.toString())}>
                { this.capitalize(moment().month(month).format('MMMM')) }</span></th>);
        }

        return result;
    };

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    sortColumn( month ){
        let arrayCopy = [...this.state.grups];

        arrayCopy.sort(function(a, b) {
            //console.log(a.data['accumulated'][month]+' / '+b.data['accumulated'][month]);
            if( parseFloat(a.data['accumulated'][month]) < parseFloat(b.data['accumulated'][month]) ){
                return 1;
            }
            if( parseFloat(a.data['accumulated'][month]) > parseFloat(b.data['accumulated'][month]) ){
                return -1;
            }
            return 0;
        });

        this.setState({grups: arrayCopy, monthOrder:month});
    }

    printTotalsRow(totals){
        let result = [];
        for(let month of this.months){
            result.push(<th key={month}>{totals[month]}</th>);
        }

        return result;
    }

    render() {
        const {grups, fetching, totals} = this.state;
        return <div className="content-page container-fluid text-center" >
            <div className="home-custom-content">
                {fetching && <div>Loading ...</div>}<Table striped size="sm">
                <thead><tr><th>&nbsp;</th>{this.printHeadersTable()}</tr>
                <tr><th>Total</th>{this.printTotalsRow(totals)}</tr></thead>
                <tbody>{grups.map((grup) => <GrupRow callbackFromParent={this.myCallback}
                                                     key={grup.id}
                                                     grup={grup}
                                                     months={this.months}
                                                     currentYear={this.currentYear} />)}
                </tbody></Table></div></div>
    }
}


const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Resume);