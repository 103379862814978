import React from 'react';
import { withAuthorization } from '../Session';
import TodoItem from '../TodoItem';
import {Table, Input, Button, ModalHeader, ModalBody, ModalFooter, Modal} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ca';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from "../Form";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.currentMonth = moment().locale('ca').format('MMMM');
        this.currentYear = moment().format('YYYY');

        // MONTHS DEFINITION WITH INTEGERS -> 0 IS JANUARY
        this.months = [];
        for( let m=2;m<=(moment().format('M')-1);m++ ){
            this.months.push(m);
        }

        this.state = {
            despeses: [],
            fetching: false,
            sumatori: 0,
            month: this.currentMonth,
            show:false,
            form_valid:false,
            execute:false
        };

        this.colRef = this.props.firebase.getDespeses( this.currentYear, this.state.month );

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        this.unsubscribeCol = this.colRef.onSnapshot( this.onColUpdate );
        this.setState({ fetching: true } );
    };

    componentWillUnmount = () => {
        this.unsubscribeCol();
    };

    onColUpdate = (snapshot) => {

        const despeses = snapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            data: docSnapshot.data()
        }));

        let acumulator = 0;
        for( let i=0; i<despeses.length ; i++ ){
            if( !isNaN(despeses[i].data['valor']) ){
                acumulator = parseFloat(acumulator) + parseFloat(despeses[i].data['valor']);
            }
        }

        this.setState({
            despeses: despeses,
            fetching: false,
            sumatori: acumulator.toFixed(2)
        });

    };

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    printSelectOptions = () =>{

        let result = [];

        for( let month of this.months ){
            let nameMonth = moment().locale('ca').month(month).format('MMMM');
            result.push(<option key={nameMonth} value={nameMonth}>{this.capitalize(nameMonth)}</option>);
        }

        return result;
    };

    handleChange = ( event ) => {
        this.setState({month: event.target.value});
        this.props.firebase.getDespeses(this.currentYear, event.target.value).onSnapshot(this.onColUpdate);
    };

    toggleModal = () => {
        this.setState( {show:this.state.show===false} );
    };

    myCallback = (isValidated) => {
        if(!isValidated){
            this.setState({form_valid:isValidated, execute:isValidated});
        }else{
            this.setState({form_valid:isValidated});
        }
    };

    sendInsertForm = () => {
        this.setState({execute:true});
        this.toggleModal();
    };

    render() {
        const {despeses, fetching, sumatori, month, show, execute} = this.state;
        return (<div className="content-page container-fluid text-center" >
                <div>
                    <Button
                        onClick={this.toggleModal}
                        className="custom-button-insert"
                        variant="secondary"
                    ><FontAwesomeIcon
                        className="custom-icons-navbar-lateral"
                        icon="arrow-alt-circle-up" />&nbsp;&nbsp;INSERIR</Button>
                <Input className="custom-month-select"
                       type="select"
                       name="currentMonth"
                       defaultValue={month}
                       onChange={this.handleChange} >{this.printSelectOptions(month)}</Input>
                <div className="home-custom-content">
                    {fetching && <div>Loading ...</div>}<Table className="custom-month-table" striped size="sm"><thead><tr><th>Dia</th><th>Concepte</th>
                    <th><span className="custom-total" >{sumatori}</span></th><th>&nbsp;</th></tr></thead>
                    <tbody>{despeses.map((despesa) => <TodoItem key={despesa.id} despesa={despesa} />)}
                    </tbody></Table></div></div>
                <Modal isOpen={show} toggle={this.toggleModal} className="">
                    <ModalHeader toggle={this.toggleModal}>Inserir despesa</ModalHeader>
                    <ModalBody>
                        <Form executation={execute} callbackFromParent={this.myCallback} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                        <Button color="primary"
                                onClick={this.sendInsertForm}
                                disabled={!this.state.form_valid} >Inserir</Button>
                    </ModalFooter>
                </Modal>
                </div>)
    }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Home);