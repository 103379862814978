import React from 'react';

import { withRouter } from 'react-router-dom';
import {AuthUserContext} from "../Session";
import NavigationAuth from '../NavigationAuth';

class NavigationLateral extends React.Component {

    render() {
        const {history, location} = this.props;
        return (
            <div id="nabvar-lateral" className="hidden-navbar">
                <AuthUserContext.Consumer>
                    {authUser =>
                        authUser ? <NavigationAuth history={history} location={location} /> : <NavigationNonAuth />
                    }
                </AuthUserContext.Consumer>
            </div>
        );
    }

}

// Empty
const NavigationNonAuth = () => ('');

export default withRouter(NavigationLateral);