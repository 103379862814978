import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import NavigationLateral from '../NavigationLateral';
// import LandingPage from '../Landing';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import Resume from '../Resume';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

class App extends React.Component {

    render = () => {
        return ( <Router>
            <Route render={ () => (
                <React.Fragment>
                    <NavigationLateral />
                    <Navigation />
                    <main id="main" >
                        <Route path={ROUTES.SIGN_IN} component={ props => <SignInPage/> } />
                        <Route exact path={ROUTES.HOME} component={ props => <HomePage /> } />
                        <Route path={ROUTES.RESUME} component={ props => <Resume/> } />
                    </main>
                </React.Fragment>
            )}
            />
        </Router> )

    }

}

export default withAuthentication(App);