import React from 'react';
import { withAuthorization } from '../Session';
import DatePicker from 'react-datepicker';
import FormErrors from './FormErrors';
import { Button, FormGroup, Input } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ca from 'date-fns/locale/es';
import moment from "moment";

class Landing extends React.Component {
    constructor(props) {
        super(props);

        this.colRef = this.props.firebase.getGrups();

        this.state = {
            text: '',
            valor: '',
            grup: 0,
            data: new Date(),
            formErrors: {text: '', valor: '', grup: '', data: ''},
            textValid: false,
            valorValid: false,
            grupValid: false,
            dataValid: true,
            formValid: false,
            grups: []
        };

        this.handleChange = this.handleChange.bind(this);

        registerLocale('ca', ca);

    }

    componentDidMount() {
        this.unsubscribeCol = this.colRef.onSnapshot(this.onColUpdate);
        this.setState({fetching: true});
    }

    componentWillUnmount() {
        this.unsubscribeCol();
    }

    UNSAFE_componentWillReceiveProps( nextProps ) {

        if( nextProps.executation !== this.props.executation ){

            const execute = nextProps.executation;
            if( execute ){
                this.addDespesa();
                this.props.callbackFromParent(false);
            }

        }


        /*
        const execute = nextProps.executation;
        if( execute ){
            this.addDespesa();
            this.props.callbackFromParent(false);
        }
        */


    }

    onColUpdate = (snapshot) => {
        const grups = snapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            data: docSnapshot.data()
        }));

        this.setState({
            grups: grups
        });

    };

    handleChange(date) {
        this.setState({
            data: date
        });
        this.setState({data: date},
            () => { this.validateField('data', date) });
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value) });
    }

    addDespesa = () => {
        // e.preventDefault();

        this.props.firebase.addDespesa(this.state, moment( this.state.data ).format('YYYY'),
            moment( this.state.data ).locale('ca').format('MMMM') ).then( () => {

            this.setState({
                text: '',
                valor: '',
                grup: 0,
                data: new Date(),
                formErrors: {text: '', valor: '', grup: '', data: ''},
                textValid: false,
                valorValid: false,
                grupValid: false,
                dataValid: true,
                formValid: false
            });

        }).catch(function(error){
            console.log(error);
        });

    };

    validateField(fieldName, value) {

        let fieldValidationErrors = this.state.formErrors;
        let textValid = this.state.textValid;
        let valorValid = this.state.valorValid;
        let grupValid = this.state.grupValid;
        let dataValid = this.state.dataValid;

        switch(fieldName) {
            case 'text':
                textValid = value.trim().length >= 1;
                fieldValidationErrors.text = textValid ? '' : ' is empty.';
                break;
            case 'valor':
                valorValid = value.length >= 1 && !isNaN(value);
                fieldValidationErrors.valor = valorValid ? '': ' is not a valid number.';
                break;
            case 'grup':
                grupValid = value.length >= 1;
                fieldValidationErrors.grup = grupValid ? '': ' is not chosen.';
                break;
            case 'data':
                dataValid = value.toString().length >= 4;
                fieldValidationErrors.data = dataValid ? '': ' is not a valid data.';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
            textValid: textValid,
            valorValid: valorValid,
            grupValid: grupValid,
            dataValid: dataValid
        }, this.validateForm);
    }

    validateForm() {

        let isValidated = this.state.textValid && this.state.valorValid && this.state.grupValid && this.state.dataValid;

        this.setState({formValid: isValidated});
        this.props.callbackFromParent( isValidated );
    }

    render() {
        const {grups} = this.state;
        return (

            <div className="custom-content-form">
                <form onSubmit={this.addDespesa}>
                    <FormGroup>
                        <FontAwesomeIcon
                            icon="pencil-alt" />
                        <Input
                            type="text"
                            name="text"
                            className={this.state.textValid?'is-valid':'is-invalid'}
                            placeholder="text"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.text}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FontAwesomeIcon
                            icon="euro-sign" />
                        <Input
                            type="text"
                            name="valor"
                            className={this.state.valorValid?'is-valid':'is-invalid'}
                            placeholder="eurus"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.valor}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FontAwesomeIcon
                            icon="layer-group" />
                        <Input type="select"
                               id="id-grup"
                               className={this.state.grupValid?'is-valid':'is-invalid'}
                               onChange={(event) => this.handleUserInput(event)}
                               value={this.state.grup}
                               name="grup">
                            <option disabled={true} value="0" >grup</option>;
                            {grups.map((grup) => <CustomOption key={grup.id} grup={grup} ></CustomOption>)}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <FontAwesomeIcon
                            icon="calendar-day" />
                        <DatePicker
                            className={(this.state.dataValid?'is-valid':'is-invalid')+" form-control custom-datepicker"}
                            selected={this.state.data}
                            onChange={(event) => this.handleChange(event)}
                            dateFormat="dd/MM/yyyy"
                            locale='ca'
                            name="data"
                        />
                    </FormGroup>
                </form>

                <div className="panel panel-default">
                    <FormErrors formErrors={this.state.formErrors} />
                </div>

            </div>
        );
    }
}

class CustomOption extends React.Component {

    render() {
        const {grup} = this.props;
        const {clau, nom} = grup.data;
        return <option value={clau} >{nom}</option>;
    }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(Landing);