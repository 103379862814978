import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAuthorization } from '../Session';
import moment from "moment";
import 'moment/locale/ca';
import ContentEditable from 'react-contenteditable';

class TodoItem extends React.Component {

    constructor(props) {
        super(props);

        const {despesa} = this.props;

        this.id = this.props.despesa.id;

        this.month = moment( despesa.data['data'].toDate() ).locale('ca').format('MMMM');
        this.year = moment( despesa.data['data'].toDate() ).format('YYYY');

        this.state = {
            despesa: despesa.data
        };

        // console.log('id'+id+' '+month+' '+year);

        this.colRef = this.props.firebase.getDespesa(this.id, this.month, this.year);

    }

    componentDidMount() {
        this.unsubscribeCol = this.colRef.onSnapshot( this.onColUpdate );
        // this.setState({fetching: true});
    }

    componentWillUnmount() {
        this.unsubscribeCol();
    }

    onColUpdate = (snapshot) => {

        this.setState({
            despesa: snapshot.data()
        });
    };

    deleteDespesa = () => {
        this.props.firebase.deleteDespesa(this.id, this.month, this.year);
    };

    handleChange = evt => {

        // this.setState({ text: evt.target.value });

        if( evt.target.value !== '<br>' ){
            this.props.firebase.setTextDespesa( evt.target.value, this.id, this.month, this.year );
        }else{
            this.setState({ despesa: this.state.despesa });
        }
    };

    validateNumber = event => {
        const keyCode = event.keyCode || event.which
        const string = String.fromCharCode(keyCode)
        const regex = /[0-9,]|\./

        if (!regex.test(string)) {
            event.returnValue = false
            if (event.preventDefault) event.preventDefault()
        }
    };

    handleKeyPress = evt => {

        if( evt.key === 'Enter' ){
            evt.returnValue = false;
            evt.target.blur();
            if (evt.preventDefault) evt.preventDefault();
        }
    };

    capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const {despesa} = this.state;
        return <tr><td><table className="custom-day-container" ><tbody>
            <tr><td className="custom-field-day-top" >{this.capitalize(moment( despesa['data'].toDate() ).format('dddd'))}</td></tr>
            <tr><td className="custom-field-day" >{moment( despesa['data'].toDate() ).format('DD')}</td></tr></tbody></table>
                </td>
            <td>
            <ContentEditable
                html={despesa['text']}
                className='content-editable'
                disabled={false}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
            /><span
                className={'badge badge-'+despesa['grup']}>{despesa['grup']}</span>
        </td>
            <td><span>{despesa['valor'].replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1')}</span></td>
            <td>
                <FontAwesomeIcon
                    className="custom-icon-remove"
                    icon="trash"
                    onClick={() => this.deleteDespesa()}
                />
            </td></tr>;


    }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(TodoItem);