import React from 'react';
import * as ROUTES from "../../constants/routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SignOut from "../SignOut";

class NavigationAuth extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    onClickMenuElement = (element) => {
        const { history, location } = this.props;
        if( location.pathname !== element ){
            history.push(element);
        }

    };

    render = () => {
        return (
            <div className="list-navigation" >
                <div onClick={() => this.onClickMenuElement(ROUTES.HOME)}
                    className={(window.location.href === window.location.protocol+'//'+window.location.host+ROUTES.HOME ? 'active' : '')} >
                    <FontAwesomeIcon
                    className="custom-icons-navbar-lateral"
                    icon="calendar-alt" />
                    <span>&nbsp;&nbsp;MENSUAL</span></div>
                <div onClick={() => this.onClickMenuElement(ROUTES.RESUME)}
                    className={(window.location.href === window.location.protocol+'//'+window.location.host+ROUTES.RESUME ? 'active' : '')} >
                    <FontAwesomeIcon
                    className="custom-icons-navbar-lateral"
                    icon="align-justify" />
                    <span>&nbsp;&nbsp;RESUM</span></div>
                <div>&nbsp;</div>
                <div><SignOut/></div>
            </div>

        );
    }

}

export default NavigationAuth;