import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyCubYGXFGQNnFHIdKn7ZGV0gQSENhJK_C8',
    authDomain: 'despeses-cutibella.firebaseapp.com',
    databaseURL: 'https://despeses-cutibella.firebaseio.com',
    projectId: 'despeses-cutibella',
    storageBucket: 'despeses-cutibella.appspot.com',
    messagingSenderId: '692324671694',
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();

        this.googleProvider = new app.auth.GoogleAuthProvider();
    }

    // *** Auth API ***

    isLogged = () => this.auth.currentUser !== null?true:false;

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () =>
        //this.auth.signInWithRedirect(this.googleProvider);
        this.auth.signInWithPopup(this.googleProvider);

    doSignOut = () => this.auth.signOut();

    //user = uid => this.db.ref(`users/${uid}`);

    getDespeses = (year, month) => this.db.collection('despeses').doc(year).collection(month).orderBy('data','desc');

    getDespesa = (id, month, year) => this.db.collection('despeses').doc(year).collection(month).doc(id);

    getDespesesByClau = (year, month, clau) =>  this.db.collection('despeses')
        .doc(year).collection(month).where('grup', '==', clau);

    getGrups = () => this.db.collection('grups');

    addDespesa = (data, year, month) => this.db.collection('despeses').doc(year).collection(month).add({
        text: data.text,
        valor: parseFloat(data.valor).toFixed(2),
        data: data.data,
        grup: data.grup
    });

    setTextDespesa = (text, id, month, year) => this.db.collection('despeses').doc(year)
        .collection(month).doc(id).update({text:text});

    deleteDespesa = (id, month, year) => this.db.collection('despeses').doc(year)
        .collection(month).doc(id).delete().then( function(){
            // Nothing?
        } ).catch(function(error) {
            console.log(error);
        });

}

export default Firebase;