import React from 'react';
import { withAuthorization } from '../Session';
import moment from "moment";
import 'moment/locale/ca';

class GrupRow extends React.Component {

    constructor(props) {
        super(props);

        const {grup} = this.props;
        const {clau} = grup.data;

        const year = this.props.currentYear;

        this.months = this.props.months;
        this.clau = clau;

        this.state = {
            despeses: [],
            fetching: false,
            acumulators: [],
            grup: grup
        };

        this.colRef = [];
        this.unsubscribeCol = [];

        for( let month of this.months ){
            this.colRef[month] =
                this.props.firebase.getDespesesByClau(year, moment().month(month).format('MMMM'), clau);
            this.state.acumulators[month] = 0;
        }

    }

    componentDidMount() {

        for( let month of this.months ) {
            this.unsubscribeCol[month] = this.colRef[month].onSnapshot(this.onColUpdate);
        }

        // this.setState({fetching: true});
    }

    componentWillUnmount() {

        for( let month of this.months ) {
            this.unsubscribeCol[month]();
        }

    }

    onColUpdate = (snapshot) => {

        const despeses = snapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            data: docSnapshot.data()
        }));

        const { acumulators } = this.state;

        let acumulator = 0;

        for( let i=0; i<despeses.length; i++ ){

            let month_despesa = moment( despeses[i].data['data'].toDate() ).locale('ca').month();

            if( !isNaN(despeses[i].data['valor']) ){
                acumulator = parseFloat(acumulator) + parseFloat(despeses[i].data['valor']);
            }

            this.props.callbackFromParent( acumulator - acumulators[month_despesa], month_despesa, this.clau );

            acumulators[month_despesa] =
                acumulator.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/,'$1');


        }



        this.setState({
            despeses: despeses,
            fetching: false,
            acumulators: acumulators
        });

    };

    printRowMonths = (acumulators) =>{
        let result = [];
        for( let month of this.months ){
            result.push(<td key={month}>{acumulators[month]}</td>);
        }

        return result;
    };

    render = () => {
        const {grup, acumulators} = this.state;
        const {clau} = grup.data;
        return <tr><td><span
            className={'badge badge-'+clau}>{clau}</span></td>{this.printRowMonths(acumulators)}</tr>;
    }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(GrupRow);