import React from 'react';
import { Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import { withFirebase } from "../Firebase";
import SignOut from "../SignOut";

class Navigation extends React.Component {

    toggleLateralMenu = () => {

        if( this.props.firebase.isLogged() ){

            if( !document.getElementById('nabvar-lateral').classList.contains('active') ){

                document.getElementById('nabvar-lateral').className += ' active';
                document.getElementById('main').className += ' active';

            }else{
                document.getElementById('nabvar-lateral').classList.remove('active');
                document.getElementById('main').classList.remove('active');
            }

        }
    };

    /**
     * Lo dejo aqui que mola mucho el Navbar por si lo quiero aprovechar
     *
     */

    render() {
        return (
            <div className="navigation-top" >
                <Navbar color="dark" className="navbar-dark" expand="md">
                    <NavbarBrand>
                        <img className="img-navbar" src="/despeses_icon.png" alt="" />
                        &nbsp;&nbsp;DESPESES</NavbarBrand>
                    <NavbarToggler onClick={this.toggleLateralMenu} />
                    <SignOut />

                </Navbar>
            </div>
        );
    }
}


export default withFirebase( Navigation );