import React from 'react';

export const FormErrors = ({formErrors}) =>
    <ul className='formErrors'>
        {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0){
                return (
                    <li
                        className="text-error"
                        key={i}>{fieldName}{formErrors[fieldName]}</li>
                )
            } else {
                return '';
            }
        })}
    </ul>;

export default FormErrors;