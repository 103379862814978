import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';


import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
    <div className="content-page container-fluid text-center" >
        <SignInForm />
    </div>
);

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { error: null };
    }

    onSubmit = event => {

        this.props.firebase
            .doSignInWithGoogle()
            .then(() => {
                this.setState({ error: null });
                this.props.history.push(ROUTES.LANDING);
            })
            .catch(error => {
                this.setState({ error });
            });


        event.preventDefault();
    };

    render() {
        const { error } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <button className="btn btn-info" type="submit">Sign In with Google</button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

const authCondition = authUser => authUser==null;

export default withAuthorization(authCondition)(SignInPage);

export { SignInForm };