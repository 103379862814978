import React from 'react';

import { withFirebase } from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SignOutButton extends React.Component {

    closeApp = () => {

        document.getElementById('nabvar-lateral').classList.remove('active');
        document.getElementById('main').classList.remove('active');

        this.props.firebase.doSignOut();
    };

    render = () => {
        return (
            <div className="custom-sign-out" onClick={this.closeApp} >
                <FontAwesomeIcon
                    className="custom-icons-navbar-lateral"
                    icon="sign-out-alt" />
                <span>&nbsp;&nbsp;SORTIR</span>
            </div>
        );
    }
}

export default withFirebase(SignOutButton);